<template>
    <v-container fluid>
      <div class="from-content">
        <div class="from">
          <v-form ref="form" @submit.prevent="validateForm">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  @keydown.enter="focusNext"
                  :label="$t('workingYear.min')"
                  dense
                  outlined
                  v-model="item.from_year"
                  :hint="`${server_errors.from_year}`"
                  :rules="minRules"
                  required
                >
                </v-text-field>
                <v-checkbox 
                  style="margin-top: -10px;"
                  v-model="setComparisonMarkUp" 
                  :label="$t('workingYear.comparison_mark_up')" 
                  hide-details
                  class="lfont"
                >
                </v-checkbox>
                <v-text-field
                  :disabled="setComparisonMarkUp"
                  style="margin-top: 20px;"
                  @keydown.enter="focusNext"
                  :label="$t('workingYear.max')"
                  dense
                  :rules="toYearRules"
                  required
                  outlined
                  v-model="item.to_year"
                  :hint="`${server_errors.to_year}`"
                >
                </v-text-field>
                <vuetify-money 
                  dense 
                  :outlined="true" 
                  :options="formatNumber"
                  v-model="item.budget" 
                  :hint="`${server_errors.budget}`" 
                  persistent-hint 
                  type="number"
                  :rules="budgetRules" 
                  required 
                />
              </v-col>
              <v-col cols="8">
                <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                  {{ $t("workingYear.edit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    props: {
      item: {},
    },
    data() {
      return {
        minRules: [
          v => (v !== null && v !== '') || 'field is required...',
        ],
        maxRules: [
          v => !!v || 'field is required...'
        ],
        budgetRules: [
          v => !!v || 'field is required...'
        ],
        formatNumber: {
          length: 11,
          precision: 0
        },
        setComparisonMarkUp: false,
        server_errors: {
          name: "",
          from_year: "",
          to_year: "",
          budget: "",
        },
        btnLoading: false,
      }
    },
    computed: {
      toYearRules() {
        return this.setComparisonMarkUp ? [] : this.maxRules;
      },
    },
    watch: {
      'item.to_year'(newValue) {
        if (newValue === null || newValue === '') {
          this.setComparisonMarkUp = true;
        } else {
          this.setComparisonMarkUp = false;
        }
      },
    },
    methods: {
      focusNext(e) {
        const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
        const index = inputs.indexOf(e.target);
        if (index < inputs.length) {
          inputs[index + 1].focus();
        }
      },
      validateForm() {
        if (this.$refs.form.validate()) {
          this.saveChange();
          this.btnLoading = true;
        }
      },
      saveChange() {
        this.$axios.put(`company/annual-bonus/${this.item.id}`, {
          from_year: this.item.from_year,
          to_year: this.item.to_year,
          budget: this.item.budget,
        }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit('close');
              this.$emit('success');
              this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
            }, 300);
            this.btnLoading = false;
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .from-content {
    width: 100%;
    height: 300px;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .from {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  }
  </style>
  