<template>
    <v-container fluid>
      <div class="from-content">
        <div class="from">
          <v-form ref="form" @submit.prevent="validateForm">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    @keydown.enter="focusNext"
                    :label="$t('educationLevel.name')"
                    dense
                    outlined
                    v-model="item.name"
                >
                </v-text-field>
                <vuetify-money 
                    :outlined="true" 
                    :options="formatNumber" 
                    :clearable="true" 
                    dense
                    v-model="item.budget" 
                    type="number" 
                    class="input-number" 
                    :hint="`${server_errors.budget}`"
                    persistent-hint 
                    :rules="budgetRules"
                    required
                />
              </v-col>
              <v-col cols="8">
                <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                  {{ $t("educationLevel.edit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    props: {
      item: {},
    },
    data() {
      return {
        budgetRules: [
          v => !!v || 'field is required...'
        ],
        formatNumber: {
          length: 11,
          precision: 0
        },
        server_errors: {
          name: "",
          budget: 0
        },
        btnLoading: false,
      }
    },
    methods: {
      focusNext(e) {
        const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
        const index = inputs.indexOf(e.target);
        if (index < inputs.length) {
          inputs[index + 1].focus();
        }
      },
      validateForm() {
        if (this.$refs.form.validate()) {
          this.saveChange();
          this.btnLoading = true;
        }
      },
      saveChange() {
        this.$axios.put(`company/education-level/${this.item.id}`, {
          name: this.item.name,
          budget: this.item.budget
        }).then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$emit('close');
              this.$emit('success');
              this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
            }, 300);
            this.btnLoading = false;
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .from-content {
    width: 100%;
    height: 300px;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .from {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  }
  </style>
  